import styled from 'styled-components'

export const GalleryGrid = styled.section`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    margin: 0 auto;
    max-width: 1800px;
    padding: 40px 12px;
    
    @media(min-width: 700px) {
        grid-template-columns: 1fr 1fr;
        padding: 40px;
    }

    @media(min-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 40px;
    }

    .gatsby-image-wrapper {
        width: 100%;
    }
`;