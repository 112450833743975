import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
    GalleryGrid
} from './GalleryStyles'

const Gallery = () => {
    return (
        <GalleryGrid>
            <div>
                <StaticImage src="../../images/olive-tall-one.jpg" alt="" />
            </div>
            <div>
                <StaticImage src="../../images/olive-tall-two.jpg" alt="" />
            </div>
            <div>
                <StaticImage src="../../images/olive-tall-three.jpg" alt="" />
            </div>
            <div>
                <StaticImage src="../../images/olive-tall-four.jpg" alt="" />
            </div>
            <div>
                <StaticImage src="../../images/olive-tall-five.jpg" alt="" />
            </div>
            <div>
                <StaticImage src="../../images/olive-tall-six.jpg" alt="" />
            </div>
            <div>
                <StaticImage src="../../images/olive-tall-seven.jpg" alt="" />
            </div>
            <div>
                <StaticImage src="../../images/olive-tall-eight.jpg" alt="" />
            </div>
            <div>
                <StaticImage src="../../images/olive-tall-nine.jpg" alt="" />
            </div>
            <div>
                <StaticImage src="../../images/olive-tall-ten.jpg" alt="" />
            </div>
            <div>
                <StaticImage src="../../images/olive-tall-eleven.jpg" alt="" />
            </div>
            <div>
                <StaticImage src="../../images/olive-tall-twelve.jpg" alt="" />
            </div>
        </GalleryGrid>
    )
}

export default Gallery
