import React from 'react'
import Layout from '../components/Layout'
import ContactCallout from '../components/ContactCallout'
import Gallery from '../components/Gallery'

const GalleryPage = () => {
    return (
        <Layout>
            <Gallery />
            <ContactCallout heading={`Need help with choosing the right tree?`} />
        </Layout>
    )
}

export default GalleryPage
